import { useRegisterFormStore } from '~/store/registerForm';

export const useUtmParameter = () => {
  const registerFormStore = useRegisterFormStore();
  const { setUtmParameter } = registerFormStore;
  const route = useRoute();

  onMounted(() => {
    Array(route.query).forEach((q) => {
      if (
        q['utm_source'] &&
        registerFormStore.utmParameter['utmSource'] === undefined
      ) {
        setUtmParameter('utmSource', q['utm_source'] as string);
      }

      if (
        q['utm_medium'] &&
        registerFormStore.utmParameter['utmMedium'] === undefined
      ) {
        setUtmParameter('utmMedium', q['utm_medium'] as string);
      }

      if (
        q['utm_campaign'] &&
        registerFormStore.utmParameter['utmCampaign'] === undefined
      ) {
        setUtmParameter('utmCampaign', q['utm_campaign'] as string);
      }

      if (
        q['utm_content'] &&
        registerFormStore.utmParameter['utmContent'] === undefined
      ) {
        setUtmParameter('utmContent', q['utm_content'] as string);
      }
    });
  });
};
