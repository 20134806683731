export type Header = {
  headTitle: string;
  subTitle: string;
  isNext?: boolean;
  lp005?: {
    description: string;
  };
};

export const HEADERS = [
  {
    headTitle: '基本情報を教えてください！',
    subTitle: '現在の転職意欲を教えてください',
  },
  {
    headTitle: '基本情報を教えてください！',
    subTitle: '誕生年を教えてください',
  },
  {
    headTitle: '基本情報を教えてください！',
    subTitle: '誕生月を教えてください',
  },
  {
    headTitle: '基本情報を教えてください！',
    subTitle: '誕生日を教えてください',
  },
  {
    headTitle: '希望の条件を教えてください！',
    subTitle: '希望勤務地を教えてください（最大5つ）',
    isNext: true,
  },
  {
    headTitle: 'ご経験を教えてください！',
    subTitle: '直近の在籍企業を教えてください',
    isNext: true,
  },
  {
    headTitle: 'ご経験を教えてください！',
    subTitle: 'メインで行った業務の職種を教えてください',
    lp005: {
      description:
        '※情シスを経験した方は、「情報システム・コーポレートIT」を選択してください',
    },
  },
  {
    headTitle: 'ご経験を教えてください！',
    subTitle: '現在の年収を教えてください',
  },
  {
    headTitle: '基本情報を教えてください！',
    subTitle: '電話番号を教えてください',
    isNext: true,
  },
  {
    headTitle: '最後のページです！',
    subTitle: 'アカウント情報を入力して申し込みを完了しましょう',
  },
] as const satisfies Header[];
