import { HEADERS } from '~/const/lp/lp001/header';
import { useRegisterFormStore } from '~/store/registerForm';

interface NextButton {
  isNext: boolean;
  isDisabled: boolean;
}

export function useRegisterLayout() {
  const registerFormStore = useRegisterFormStore();
  const { setSlidePattern, setCurrentStep } = registerFormStore;

  const headers = computed(() => {
    return HEADERS;
  });

  const nextButton = computed<NextButton>(() => {
    switch (registerFormStore.currentStep) {
      case 5: {
        return {
          isNext: true,
          isDisabled:
            registerFormStore.clientProfile.desiredLocations.length === 0,
        };
      }
      case 6: {
        return {
          isNext: true,
          isDisabled: registerFormStore.clientProfile.latestCompanyName === '',
        };
      }
      case 9: {
        return {
          isNext: true,
          isDisabled:
            registerFormStore.formError.phoneNumber.isError ||
            registerFormStore.clientProfile.phoneNumber === '',
        };
      }
      default:
        return {
          isNext: false,
          isDisabled: false,
        };
    }
  });

  const onClickBackButton = () => {
    setCurrentStep(registerFormStore.currentStep - 1);
    setSlidePattern('prev');
  };

  const onClickNextButton = () => {
    setCurrentStep(registerFormStore.currentStep + 1);
    setSlidePattern('next');
  };

  return { headers, onClickBackButton, onClickNextButton, nextButton };
}
